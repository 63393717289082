@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');



html, body {
  margin: 0;
  padding: 0;
  width: 100%;

}
 

